<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined>
          <v-card-title>
            <v-icon class="mr-3" color="primary">mdi-group</v-icon>
            <h3>Nova Tag</h3>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" :to="{ name: 'tag-list' }" exact>
              Voltar
            </v-btn>
          </v-card-title>
          <v-row>
            <v-card-text>
              <v-col cols="12">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-text-field
                    v-model="tag_form.name"
                    label="Nome"
                    :rules="nameRules"
                    outlined
                    dense
                    required
                  >
                  </v-text-field>
                  <v-card outlined>
                    <v-card-text class="subtitle-1">
                      Selecione um icone
                    </v-card-text>
                    <div class="pa-4">
                      <v-chip-group
                        v-model="selectedIcon"
                        active-class="primary"
                        column
                      >
                        <v-chip
                          v-for="icon in icons"
                          :key="icon.name"
                          :value="icon.name"
                        >
                          <v-icon>{{ icon.name }}</v-icon>
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-card>
                </v-form>
              </v-col>
              <v-col cols="12" align="right">
                <v-btn
                  color="primary"
                  @click="saveTagItem"
                  :loading="creating"
                  :disabled="creating"
                >
                  Salvar
                </v-btn>
              </v-col>
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      selectedIcon: "",
      tag_form: {
        name: "",
        icon: ""
      },
      creating: false,
      valid: true,
      nameRules: [v => !!v || "Nome obrigatório"]
    };
  },
  computed: {
    ...mapState("icon", ["icons"]),
    ...mapState("tag", ["tag_save"])
  },
  methods: {
    ...mapActions("tag", ["saveTag"]),

    async saveTagItem() {
      this.tag_form.icon =
        this.selectedIcon == undefined || this.selectedIcon == ""
          ? ""
          : this.selectedIcon;
      if (this.$refs.form.validate()) {
        this.creating = true;
        const params = this.tag_form;
        await this.saveTag(params).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        if (this.tag_save.success) {
          this.$swal({
            icon: "success",
            title: this.tag_save.message,
            showCancelButton: true,
            confirmButtonText: "Continuar cadastrando",
            cancelButtonText: "Listagem",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#445E93",
            showLoaderOnConfirm: true
          }).then(result => {
            if (!result.value) {
              this.$router.push({
                name: "tag-list"
              });
            } else {
              this.$refs.form.reset();
              this.selectedIcon = "";
              this.$refs.form.resetValidation();
            }
          });
        } else {
          await this.$swal("Oops ...", this.tag_save.message, "warning");
        }
        this.creating = false;
      }
    }
  }
};
</script>
